import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import adminIndex from '../views/admin/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/views/index/IndexHome.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/Index.vue'),
      },
      {
        path: '/course',
        name: 'courseList',
        component: () => import('@/views/index/course/CourseList.vue'),
      },
      {
        path: '/course/info/:id',
        name: 'courseInfo',
        meta: { isAuth: true, },
        component: () => import('@/views/index/course/CourseInfo.vue'),
      },
      {
        path: '/courseVideo/info/:id',
        name: 'courseInfo',
        meta: { isAuth: false, },
        component: () => import('@/views/index/course/CourseVideoInfo.vue'),
      },
      {
        path: '/news',
        name: 'newsList',
        component: () => import('@/views/index/news/NewsList.vue'),
      },
      {
        path: '/news/info/:id',
        name: 'newsInfo',
        component: () => import('@/views/index/news/NewsInfo.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/index/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/index/Register.vue'),
  },

  {
    path: '/admin',
    name: 'adminIndex',
    component: adminIndex,
    redirect: '/admin/banner',
    meta: { isAuthAdmin: true, },
    children: [
      {
        // 当 /user/:id/profile 匹配成功
        // UserProfile 将被渲染到 User 的 <router-view> 内部
        path: '/admin/banner',
        component: () => import('@/views/admin/banner/Banner.vue'),
        meta: { isAuthAdmin: true, },

      },
      {
        path: '/admin/banner/add',
        component: () => import('@/views/admin/banner/BannerAdd.vue'),
        meta: { isAuthAdmin: true, },

      },
      {
        path: '/admin/banner/edit/:id',
        component: () => import('@/views/admin/banner/BannerEdit.vue'),
        meta: { isAuthAdmin: true, },

      },
      {
        path: '/admin/course',
        component: () => import('@/views/admin/course/Course.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/add',
        component: () => import('@/views/admin/course/CourseAdd.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/addVideo',
        component: () => import('@/views/admin/course/CourseAddVideo.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/info/:id',
        component: () => import('@/views/admin/course/CourseInfo.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/infoVideo/:id',
        component: () => import('@/views/admin/course/CourseInfoVideo.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/edit/:id',
        component: () => import('@/views/admin/course/CourseEdit.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/course/editVideo/:id',
        component: () => import('@/views/admin/course/CourseEditVideo.vue'),
        meta: { isAuthAdmin: true, },
      },
      {
        path: '/admin/member',
        component: () => import('@/views/admin/member/Member.vue'),
        meta: { isAuthAdmin: true, },


      },
      {
        path: '/admin/news',
        component: () => import('@/views/admin/news/News.vue'),
        meta: { isAuthAdmin: true, },

      },
      {
        path: '/admin/news/add',
        component: () => import('@/views/admin/news/NewsAdd.vue'),
        meta: { isAuthAdmin: true, },

      },
      {
        path: '/admin/news/edit/:id',
        meta: { isAuthAdmin: true, },
        component: () => import('@/views/admin/news/NewsEdit.vue'),
      },
      {
        path: '/admin/config',
        meta: { isAuthAdmin: true, },
        component: () => import('@/views/admin/config/Config.vue'),

      },
      {
        path: '/admin/config/user',
        meta: { isAuthAdmin: true, },
        component: () => import('@/views/admin/config/ConfigUser.vue'),
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Login.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]





const router = new VueRouter({
  // mode: 'history',

  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.meta.isAuth || to.meta.isAuthAdmin) {     //判断是否需要鉴权

    if (to.meta.isAuth) {
      if (localStorage.getItem('token') != null) {
        next();
      } else {
        // alert('请登录');
        MessageBox.confirm('请登录后继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          next({ name: 'login' })
        }).catch(() => {

        });
      }
    }

    if (to.meta.isAuthAdmin) {
      if (localStorage.getItem('adminToken') != null) {
        next();
      } else {
        // alert('请登录');
        MessageBox.confirm('请登录后继续', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          next({ name: 'adminLogin' })
        }).catch(() => {

        });
        next({ name: 'adminLogin' })
      }
    }

  } else {
    next()
  }

})


export default router
