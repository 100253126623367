<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <el-container style="height: 100vh; border: 1px solid #eee">
      <el-aside width="250px">
        <el-menu :default-active="$route.path" :router="true">
          <el-menu-item index="/admin/banner">
            <i class="el-icon-collection"></i>
            <span slot="title">Banner管理</span>
          </el-menu-item>
          <el-menu-item index="/admin/course">
            <i class="el-icon-edit"></i>
            <span slot="title">课程管理</span>
          </el-menu-item>
          <el-menu-item index="/admin/news">
            <i class="el-icon-document"></i>
            <span slot="title">资讯管理</span>
          </el-menu-item>
          <el-menu-item index="/admin/member">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <!-- <el-submenu index="/admin/config">
            <template slot="title">
              <i class="el-icon-setting"></i
              <span>设置</span>
            </template>
            <el-menu-item-group>
              <template slot="title"></template>
              <el-menu-item index="/admin/config">基本信息</el-menu-item>
              <el-menu-item index="/admin/config/user">成员管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
        </el-menu>
      </el-aside>

      <el-container>
        <el-header style="text-align: right; font-size: 12px">
          <template v-if="userInfo">
            <span>{{ userInfo.nickname }}</span>
            <el-dropdown @command="handleCommand">
              <i class="el-icon-setting" style="margin-left: 15px"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-header>

        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: 'adminIndexView',
  components: {

  },
  data() {
    return {
      isCollapse: false,
      userInfo: {}
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('adminUserInfo'))
  },
  methods: {
    handleCommand(command) {
      if (command == 'logout') {
        this.logout()
      }
    },
    logout() {
      localStorage.removeItem('adminUserInfo')
      localStorage.removeItem('adminToken')
      this.$router.push('/admin/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #FFF;
  color: #333;
  line-height: 60px;
  box-shadow: 1px 0px 20px 5px rgba(136, 136, 136, 0.15);
}

.el-aside {
  color: #333;
  background-color: #fff;
  box-shadow: 1px 0px 20px 5px rgba(136, 136, 136, 0.15);
  z-index: 1;
}
</style>
