import moment from 'moment';
export default {

    /**
     * 图片路径转换
     * @param {String} img_path 图片地址
     * @param {Object} params 参数，针对商品、相册里面的图片区分大中小，size: big、mid、small
     */
    img(img_path, params) {
        var path = "";
        var default_img = "";
        if (img_path != undefined && img_path != "") {
            if (params) {
                // 过滤默认图
                let arr = img_path.split(".");
                let suffix = arr[arr.length - 1];
                arr.pop();
                arr[arr.length - 1] = arr[arr.length - 1] + "_" + params.size;
                arr.push(suffix);
                img_path = arr.join(".");
            }

            if (img_path.indexOf("http://") == -1 && img_path.indexOf("https://") == -1) {
                path = process.env.VUE_APP_IMG_DOMAIN + "/" + img_path;
            } else {
                path = img_path;
            }
            // 处理商品助手的图片路径
            // path = path.replace("addons/NsGoodsAssist/", "").replace("shop/goods/", "");
        }
        return path;
    },
    /**
     * 时间戳格式化
     * @param {String} time 
     * 
     */
    formatTime(time) {
        return moment(time).format('yyyy-MM-DD')
    }
};
